import React from 'react';

const ContentPanel = ({className, children}) => (
  <div
    className={`content-panel__container${className ? ' ' + className : ''}`}
  >
    {children}
  </div>
);

export default ContentPanel;
