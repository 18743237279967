import React from "react"

import Layout from "@components/layout"
import SEO from "@components/seo"
import ContentPanel from "@components/content-panel"
import { Link } from "gatsby";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="main__container">
      <ContentPanel>
        <h1>Page not found</h1>
        <Link to="/">Back to challenge results</Link>
      </ContentPanel>
    </div>
  </Layout>
)

export default NotFoundPage
